import { connect } from 'react-redux';
import React from 'react';
import Icon from '../../icons/Icon';
import IconStarOutline from '../../icons/IconStarOutline';
import IconStarFilled from '../../icons/IconStarFilled';
import { postFavorite, deleteFavorite } from '../../../actions/api';
import { updateFavModel } from '../../../actions/local';
import { selectUser } from '../../../selectors/user';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const StarContainerStyle = styled(motion.div)`
  transform-origin: center;
`;

class StarContainer extends React.Component {
  onClickFav = event => {
    event.preventDefault();
    event.stopPropagation();
    const { appId, eventId } = this.props.matchParams;

    const data = {
      app_id: +appId,
      event_id: +eventId,
      favorite_id: this.props.model.id,
      favorite_type: this.props.type
    };
    if (!this.props.model.is_favorite) {
      this.props.postFavorite(data);
    } else {
      this.props.deleteFavorite(data);
    }

    this.props.updateFavModel({
      data: { id: this.props.model.id, is_favorite: !this.props.model.is_favorite },
      type: this.props.type,
      favorite_id: this.props.favorite_id
    });
  };

  render() {
    if (!this.props.user || !this.props.agendaIsStarable) {
      return null;
    }

    const variants = {
      fav: { rotate: 360 },
      notFav: { rotate: -360 }
    };

    return (
      <StarContainerStyle
        key={`favorite_star_${this.props.model.id}`}
        animate={this.props.model.is_favorite ? 'fav' : 'notFav'}
        variants={variants}
        initial={false}
        onClick={this.props.subIconAction}
      >
        <Icon
          icon={this.props.model.is_favorite ? IconStarFilled : IconStarOutline}
          width={this.props.size || '40px'}
          height={this.props.size || '40px'}
          padding={this.props.padding || '8px'}
          margin={'0'}
          color={this.props.theme.mainColor}
          onClick={event => {
            this.onClickFav(event);
          }}
        />
      </StarContainerStyle>
    );
  }
}

const mapDispatchToProps = {
  postFavorite: postFavorite,
  deleteFavorite: deleteFavorite,
  updateFavModel: updateFavModel
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUser(state, ownProps)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StarContainer);
